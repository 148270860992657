import type { ElementType, MouseEvent, ReactNode, Ref } from "react";
import styled from "styled-components";
import type {
  SpaceProps,
  LayoutProps,
  TypographyProps,
  ColorProps,
  FlexboxProps,
  BorderBottomProps,
} from "styled-system";
import {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
} from "styled-system";
import css, { get } from "@styled-system/css";

import shouldNotForwardProps from "Shared/helpers/shouldNotForwardProps";

export type BoxProps = SpaceProps &
  LayoutProps &
  TypographyProps &
  ColorProps &
  BorderBottomProps &
  FlexboxProps & {
    as?: ElementType | undefined;
    children?: ReactNode;
    ref?: Ref<TODO>;
    onClick?: (e: MouseEvent) => void;
  };

const sx = (props: any) => css(props.sx)(props.theme);
const base = (props: any) => css(props.__css)(props.theme);
const variant = ({ theme, variant, tx = "variants" }: any) =>
  css(get(theme, tx + "." + variant, get(theme, variant)))(theme);

// TODO: use shouldNotForwardProps to get rid of all the box related console errors
const Box = styled.div.withConfig(
  shouldNotForwardProps([
    "justifyContent",
    "justifySelf",
    "flexWrap",
    "alignSelf",
    "alignItems",
    "flexDirection",
    "flexGrow",
    "maxWidth",
    "maxHeight",
    "minHeight",
    "lineHeight",
    "overflowY",
    "backgroundColor",
    "borderRadius",
    "textAlign",
    "marginTop",
    "gridArea",
    "gridTemplateRows",
  ]),
)<BoxProps>(
  {
    boxSizing: "border-box",
    margin: 0,
    minWidth: 0,
  },
  base,
  variant,
  sx,
  // (props) => props.css,
  compose(space, layout, typography, color, flexbox),
);

export default Box;
