import * as yup from "yup";
import { format } from "date-fns";
import type { TFunction } from "i18next";
import type { AnyObject, Maybe } from "yup/lib/types";

import type { SsnMetaData } from "Shared/helpers/socialSecurityUtils";
import { validateSSN } from "Shared/helpers/socialSecurityUtils";
import i18n from "./i18n";

declare module "yup" {
  interface DateSchema<
    TType extends Maybe<Date> = Date | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    daterangeStart(endDate: string): DateSchema<TType, TContext>;
    daterangeEnd(startDate: string): DateSchema<TType, TContext>;
  }
}

function color() {
  //@ts-ignore WebStorm supports 'this' type starting from v11.0.3
  return this.matches(
    /^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/,
    "Please enter valid color",
  );
}

yup.addMethod(yup.string, "color", color);

// function email() {
//   //@ts-ignore WebStorm supports 'this' type starting from v11.0.3
//   return this.matches(
//     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
//     i18n.t("yup.string.email")
//   );
// }

function email() {
  //@ts-ignore
  return this.test(
    "custom-email",
    i18n.t("yup.string.email"),
    //@ts-ignore
    function (value) {
      if (!value || !value.length)
        return true
      //@ts-ignore//
      const { path, createError } = this;
      // ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
      const customEmailRegex = /^[\w+\-.]+@[a-z\d-]+(\.[a-z\d-]+)*\.[a-z]+$/i
      return (
        customEmailRegex.test(value) ||
        createError({ path, message: i18n.t("yup.string.email") })
      );
    }
  );
}

yup.addMethod(yup.string, "email", email);

function username() {
  //@ts-ignore
  return this.test(
    "custom-email",
    i18n.t("yup.string.email"),
    //@ts-ignore
    function (value) {
      if (!value || !value.length)
        return true
      //@ts-ignore
      const { path, createError } = this;
      const customEmailRegex = /^[a-zA-Z0-9._%@+-]+$/;
      return (
        customEmailRegex.test(value) ||
        createError({ path, message: i18n.t("yup.mixed.notType") })
      );
    }
  );
}

yup.addMethod(yup.string, "username", username);

function svNumber(gender: SsnMetaData["gender"], lastName: string) {
  //@ts-ignore
  return this.test(
    "social-security-number",
    i18n.t("profile.ssn-validation-error"),
    function (value: string) {
      //@ts-ignore
      const { path, createError } = this;

      try {
        const errorMessage = validateSSN(value, {
          gender,
          lastName,
        });

        if (!errorMessage) {
          return true;
        } else {
          return createError({ path, message: errorMessage });
        }
      } catch (_) {
        return createError({
          path,
          message: i18n.t("profile.ssn-validation-error"),
        });
      }
    },
  );
}

yup.addMethod(yup.string, "svNumber", svNumber);

function daterangeStart(endAttr: string) {
  let errorMsg = "Bitte wählen Sie ein früheres Datum";
  //@ts-ignore
  return this.test(
    "daterange-start",
    errorMsg,
    function (value: Date | string) {
      //@ts-ignore
      if (!this.parent[endAttr] || this.parent[endAttr] === "") return true;
      if (value === null || value === undefined || value === "") return true;
      //@ts-ignore
      const { path, createError } = this;
      try {
        //@ts-ignore
        const isValid = value <= this.parent[endAttr];
        return isValid || createError({ path, message: errorMsg });
      } catch (_) {
        return createError({ path, message: errorMsg });
      }
    },
  );
}

yup.addMethod(yup.date, "daterangeStart", daterangeStart);

function daterangeEnd(startAttr: string) {
  let errorMsg = "Bitte wählen Sie ein späteres Datum";
  //@ts-ignore
  return this.test(
    "daterange-start",
    errorMsg,
    function (value: Date | string) {
      //@ts-ignore
      if (!this.parent[startAttr] || this.parent[startAttr] === "") return true;
      if (value === null || value === undefined || value === "") return true;
      //@ts-ignore
      const { path, createError } = this;
      try {
        //@ts-ignore
        const isValid = value >= this.parent[startAttr];
        return isValid || createError({ path, message: errorMsg });
      } catch (_) {
        return createError({ path, message: errorMsg });
      }
    },
  );
}

yup.addMethod(yup.date, "daterangeEnd", daterangeEnd);

export const translateError = (t: TFunction): void => {
  yup.setLocale({
    mixed: {
      default: t("yup.mixed.default"),
      required: t("yup.mixed.required"),
      oneOf: t("yup.mixed.oneOf"),
      notType: t("yup.mixed.notType"),
      notOneOf: t("yup.mixed.notOneOf"),
    },
    string: {
      length: t("yup.string.length"),
      min: t("yup.string.min"),
      max: t("yup.string.max"),
      matches: t("yup.string.matches"),
      email: t("yup.string.email"),
      url: t("yup.string.url"),
      trim: t("yup.string.trim"),
      lowercase: t("yup.string.lowercase"),
      uppercase: t("yup.string.uppercase"),
    },
    number: {
      min: t("yup.number.min"),
      max: t("yup.number.max"),
      lessThan: t("yup.number.lessThan"),
      moreThan: t("yup.number.moreThan"),
      // notEqual: t("yup.number.notEqual"),
      positive: t("yup.number.positive"),
      negative: t("yup.number.negative"),
      integer: t("yup.number.integer"),
    },
    date: {
      min: ({ min }) => {
        const date = typeof min === "string" ? new Date(min) : min;
        return t("yup.date.min").replace(
          // eslint-disable-next-line no-template-curly-in-string
          "${min}",
          format(date, "dd-MM-yyyy"),
        );
      },
      max: ({ max }) => {
        const date = typeof max === "string" ? new Date(max) : max;

        return t("yup.date.max").replace(
          // eslint-disable-next-line no-template-curly-in-string
          "${max}",
          format(date, "dd-MM-yyyy"),
        );
      },
    },
    object: {},
    array: {
      min: t("yup.array.min"),
      max: t("yup.array.max"),
    },
  });
};
