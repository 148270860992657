import styled from "styled-components";

import type { BoxProps } from "../Box";
import Box from "../Box";

export { BoxProps, Box };

export const Flex = styled(Box)<BoxProps>({
  display: "flex",
});
