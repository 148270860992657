import type { Theme } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import theme from "./theme";

const muiTheme: Theme = createTheme({
  palette: {
    primary: {
      main: theme.colors.primary,
    },
    secondary: {
      main: theme.colors.darkgrey,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: { height: "40px" },
        input: {
          color: theme.colors.darkgrey,
          fontSize: "15px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.colors.blue,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.colors.blue,
          },
          backgroundColor: theme.colors.white,
        },
        notchedOutline: {
          borderColor: theme.colors.lightgrey,
          borderRadius: "8px",
          borderWidth: "2px",
        },
        input: {
          paddingTop: "20px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          paddingTop: "0px !important",
          borderRadius: "8px",
          backgroundColor: theme.colors.white,
          "&::before, &::after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled), &:active": {
            "&::before": {
              borderBottom: "none",
            },
            backgroundColor: "white",
          },
        },
        input: {
          paddingTop: "10px",
          borderBottom: "none",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          position: "relative",
          marginLeft: "-22px",
          marginTop: "-15px"
        },
        root: { minWidth: "160px" },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          minWidth: "160px",
        },
        inputRoot: {
          paddingTop: "9px",
        },
      },
    },
  },
});

export default muiTheme;
