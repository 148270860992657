import React, { Suspense, useCallback, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css"; // TODO implement in styled components
import "rc-tooltip/assets/bootstrap.css";
import "flexmonster/flexmonster.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import CookieConsentBar from "Main/CookieConsentBar";
import useSession from "Shared/Session/use";
import FullFlex from "Shared/FullFlex";
import useLocalStorage from "Shared/hooks/useLocalStorage";

import "./initScripts";
import { getBaseData } from "./api";
import routes from "./routes";
import { translateError } from "./initScripts/initYup";
import useAxiosInterceptors from "./hooks/useAxiosInterceptors";
import useSentryUserIdentification from "./hooks/useSentryUserIdentification";

const SPLASH_SCREEN_ANIMATION_TIME = 3000;

const LazyAuthApp = React.lazy(() => import("../Auth/App"));
const LazyMainApp = React.lazy(() => import("../Main/App"));
const SplashScreen = React.lazy(() => import("./SplashScreen"));

const azureLoginContinuationPaths = [
  "auth/two-factor-activation",
  "auth/confirmation",
  "auth/two-factor",
];

const isAzureContinuation = () =>
  azureLoginContinuationPaths.some((path) =>
    window.location.pathname.includes(path),
  );

const StyledFullFlex = styled(FullFlex)`
  color: ${themeGet("colors.darkgrey")};
  font-family: ${themeGet("fonts.sans")};
  font-size: ${themeGet("fontSizes.medium")};
`;

export const baseDataQueryKey = "baseData";

const RootApp: React.FC = () => {
  const { t } = useTranslation();

  useAxiosInterceptors();

  useEffect(() => {
    translateError(t);
  }, [t]);

  const queryClient = useQueryClient();
  const paramsString = new URLSearchParams(window.location.search);
  const [redirectUrl, _setRedirectUrl] = React.useState<string | null>(
    paramsString.get("redirect"),
  );

  const [SplashScreenRunning, setSplashScreenRunning] =
    React.useState<boolean>(true);
  const [showSplashScreenWhenFetching, setShowSplashScreenWhenFetching] =
    React.useState(true);

  const [azureLoginContinuation, setAzureLoginContinuation] =
    React.useState<boolean>(isAzureContinuation()); // window.location.pathname.includes("auth/confirmation")

  const [consentedToCookies, setConsentedToCookies] = useLocalStorage(
    "cookieConsent",
    false,
  );

  const { signInWith, azureLogoutRedirectMicrosoft } = useSession();

  const { data: baseData, isFetching } = useQuery({
    queryKey: [baseDataQueryKey],
    enabled: !azureLoginContinuation,
    queryFn: async () => {
      if (showSplashScreenWhenFetching) {
        setSplashScreenRunning(true);
        setTimeout(() => {
          setSplashScreenRunning(false);
        }, SPLASH_SCREEN_ANIMATION_TIME);
      }
      return getBaseData();
    },
  });

  useSentryUserIdentification(baseData?.user.id);

  const handleCookieConsent = useCallback((): void => {
    setConsentedToCookies(true);
  }, [setConsentedToCookies]);

  const handleLogin = useCallback((): void => {
    setShowSplashScreenWhenFetching(true);
    setAzureLoginContinuation(false);
    queryClient.invalidateQueries({ queryKey: [baseDataQueryKey] });
    toast.success(t("auth.login-successful"));
  }, [t, queryClient]);

  const handleLogout = useCallback(
    async (logoutErrorMessage?: string): Promise<void> => {
      setShowSplashScreenWhenFetching(false);
      if (signInWith === "AZURE" && azureLogoutRedirectMicrosoft) {
        window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${process.env.REACT_APP_API_ROOT_URL}/`;
      }
      queryClient.setQueryData([baseDataQueryKey], undefined);
      queryClient.clear();
      if (logoutErrorMessage) {
        setTimeout(() => toast.error(logoutErrorMessage), 10);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [queryClient, signInWith, azureLogoutRedirectMicrosoft],
  );

  if ((SplashScreenRunning || isFetching) && !azureLoginContinuation) {
    return <SplashScreen />;
  }

  return (
    <Suspense>
      <StyledFullFlex flexDirection="column">
        <Switch>
          <Route strict path={routes.auth}>
            {baseData ? (
              <Redirect
                from={routes.auth}
                to={redirectUrl ? `/${redirectUrl}` : routes.main}
              />
            ) : (
              <LazyAuthApp onLogin={handleLogin} />
            )}
          </Route>
          <Route path={routes.main}>
            {baseData ? (
              <>
                <LazyMainApp onLogout={handleLogout} baseData={baseData} />
                {redirectUrl && (
                  <Redirect from={routes.main} to={`/${redirectUrl}`} />
                )}
              </>
            ) : (
              <Redirect from={routes.main} to={routes.auth} />
            )}
          </Route>
        </Switch>
        <ToastContainer closeOnClick position="bottom-right" theme="colored" />
        {!consentedToCookies && (
          <CookieConsentBar onConsent={handleCookieConsent} />
        )}
      </StyledFullFlex>
    </Suspense>
  );
};

export default RootApp;
